define("ember-svg-jar/inlined/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 0h24v24H0z\" fill=\"none\"/><path d=\"M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "24",
      "viewBox": "0 0 24 24",
      "width": "24"
    }
  };
  _exports.default = _default;
});