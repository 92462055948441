define("ember-ref-bucket/modifiers/create-ref", ["exports", "ember-modifier", "@ember/application", "@ember/object", "@ember/debug", "ember-ref-bucket/utils/ref", "ember-ref-bucket/utils/prototype-reference"], function (_exports, _emberModifier, _application, _object, _debug, _ref, _prototypeReference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let RefModifier = (_class = class RefModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "_key", this.name);

      _defineProperty(this, "_ctx", this.ctx);

      _defineProperty(this, "defaultMutationObserverOptions", {
        attributes: false,
        characterData: false,
        childList: false,
        subtree: false
      });

      (0, _ref.setGlobalRef)((0, _application.getOwner)(this));
    } // to minimise overhead, user should be specific about
    // what they want to observe


    markDirty() {
      (0, _ref.bucketFor)(this._ctx).dirtyTrackedCell(this._key);
    }

    cleanMutationObservers() {
      if (this._mutationsObserver) {
        this._mutationsObserver.disconnect();
      }
    }

    cleanResizeObservers() {
      if (this._resizeObserver) {
        this._resizeObserver.unobserve(this.element);
      }
    }

    installMutationObservers() {
      this._mutationsObserver = new MutationObserver(this.markDirty);
      const opts = this.getObserverOptions();
      delete opts.resize;

      if (opts.attributes || opts.characterdata || opts.childlist) {
        // mutations observer throws if observe is attempted
        // with all these options disabled
        this._mutationsObserver.observe(this.element, opts);
      }
    }

    validateTrackedOptions() {
      const args = ['subtree', 'attributes', 'children', 'resize', 'character'];

      if (args.some(name => name in this.args.named)) {
        (false && !(this.isTracked) && (0, _debug.assert)(`"ember-ref-modifier", looks like you trying to use {{${this.args.named.debugName}}} without tracked flag or alias, but, with properties, related to tracked modifier (${args.join(', ')})`, this.isTracked));
      }
    }

    getObserverOptions() {
      // to minimise overhead user
      // should be specific about
      // what they want to observe
      let resize = false;
      let subtree = this.defaultMutationObserverOptions.subtree;
      let attributes = this.defaultMutationObserverOptions.attributes;
      let character = this.defaultMutationObserverOptions.characterData;
      let children = this.defaultMutationObserverOptions.childList;

      if ('subtree' in this.args.named) {
        subtree = this.args.named.subtree;
      }

      if ('attributes' in this.args.named) {
        attributes = this.args.named.attributes;
      }

      if ('children' in this.args.named) {
        children = this.args.named.children;
      }

      if ('resize' in this.args.named) {
        resize = this.args.named.resize;
      }

      if ('character' in this.args.named) {
        character = this.args.named.character;
      }

      return {
        subtree,
        attributes,
        childList: children,
        resize,
        characterData: character
      };
    }

    installResizeObservers() {
      this._resizeObserver = new ResizeObserver(this.markDirty);

      this._resizeObserver.observe(this.element);
    }

    didReceiveArguments() {
      (false && !(typeof this.name === 'string' && this.name.length > 0) && (0, _debug.assert)(`You must provide string as first positional argument for {{${this.args.named.debugName}}}`, typeof this.name === 'string' && this.name.length > 0));
      this.validateTrackedOptions();
      this.cleanMutationObservers();
      this.cleanResizeObservers();

      if (this.name !== this._key || this._ctx !== this.ctx) {
        (0, _ref.bucketFor)(this._ctx).add(this._key, null);
      }

      this._ctx = this.ctx;
      this._key = this.name;
      (0, _ref.watchFor)(this.name, this.ctx, () => {
        const keys = (0, _prototypeReference.getReferencedKeys)(this.ctx, this.name);
        keys.forEach(keyName => {
          // consume keys with callbacks
          this.ctx[keyName];
        });
      });
      (0, _ref.bucketFor)(this.ctx).add(this.name, this.element);

      if (this.isTracked) {
        this.installMutationObservers();

        if (this.getObserverOptions().resize) {
          this.installResizeObservers();
        }
      }
    }

    get ctx() {
      (false && !(this.args.named.bucket !== null) && (0, _debug.assert)(`ember-ref-bucket: You trying to use {{${this.args.named.debugName}}} as local reference for template-only component. Replace it to {{global-ref "${this.args.positional[0]}"}}`, this.args.named.bucket !== null));
      return this.args.named.bucket || (0, _application.getOwner)(this);
    }

    get isTracked() {
      return this.args.named.tracked || false;
    }

    get name() {
      return this.args.positional[0];
    }

    willDestroy() {
      this.cleanMutationObservers();
      this.cleanResizeObservers();
      (0, _ref.getNodeDestructors)(this.element).forEach(cb => cb());
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "markDirty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "markDirty"), _class.prototype)), _class);
  _exports.default = RefModifier;
});