define("ember-svg-jar/inlined/es-ar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st1{fill:#e22335!important}</style><circle cx=\"256\" cy=\"256\" r=\"256\" fill=\"#f7e25d\"/><path class=\"st1\" d=\"M256 0C154.5 0 66.8 59.1 25.4 144.7h461.2C445.2 59.1 357.5 0 256 0zM256 512c101.5 0 189.2-59.1 230.6-144.7H25.4C66.8 452.9 154.5 512 256 512z\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 512 512",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});