define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "app": {
      "entrarContato": "GET IN TOUCH",
      "logoDescricao": "SGCWeb logo",
      "menuAlt": "Icon menu",
      "mockupAgrupadoAlt": "Mockup two screens of SGCWEB",
      "mockupAlt": "Mockup S9 SGCWeb App",
      "privacidade": {
        "concordar": "Agree and proceed",
        "descricao": "Last Update: May 03, 2021.",
        "politica": "Privacy Policy",
        "texto1": "We use essential cookies and similar technologies in accordance with our",
        "texto2": " and by continuing to browse, you agree to these conditions.",
        "titulo": "SGCWEB PRIVACY POLICY"
      }
    },
    "contato": {
      "descricao": "Do you have any questions or suggestions? Contact us using the form below.",
      "email": {
        "placeholder": "Email",
        "titulo": "Email:",
        "validacao": "Type your e-mail"
      },
      "enviar": "To send",
      "mensagem": {
        "placeholder": "Your message:",
        "titulo": "Message:",
        "validacao": "Type your message first"
      },
      "nome": {
        "placeholder": "Enter your name",
        "titulo": "Name:",
        "validacao": "Type your name"
      },
      "telefone": {
        "placeholder": "(DDD) + phone number",
        "titulo": "Telephone:",
        "validacao": "Fill in a valid phone number"
      },
      "titulo": "Contact"
    },
    "footer": {
      "contato": "Contact",
      "direitos": "All rights reserved.",
      "endereco": "Address",
      "mapa": "Site map",
      "sgcwebDescricao": "Created based on more than 25 years of experience in the condominium management market, SGCWeb is a multiplatform tool entirely in the cloud with the objective of making life easier for those who manage condominiums or live in them.",
      "sigaNos": "Follow us",
      "sobre": "About Us",
      "sobreSystemar": "About Systemar"
    },
    "funcionalidades": {
      "administradora": {
        "itens": {
          "acesse": {
            "descricao": "SGCWEB can be accessed from any computer or cell phone with internet. Homework? No problem, you can use the system normally.",
            "titulo": "Access anywhere"
          },
          "configuravel": {
            "descricao": "Customize color themes, access permissions and work hours for your employees.",
            "titulo": "Configurable"
          },
          "financeiro": {
            "descricao": "Manage receipts, collection slips and condominium expenses in an easy and optimized way.",
            "titulo": "Complete financial control"
          },
          "melhoria": {
            "descricao": "SGCWEB is always under constant renovation, which includes tweaks, new features and cool updates.",
            "titulo": "Constant improvement"
          },
          "multicondominio": {
            "descricao": "The system allows controlling several condominiums simultaneously, ideal for condominium administrators.",
            "titulo": "Multi-condominiums"
          },
          "relatorios": {
            "descricao": "Dozens of reports from the most varied categories, and with several options, for you to display information the way you want.",
            "titulo": "All the reports you need"
          }
        },
        "titulo": "MORE EFFICIENCY FOR YOUR ADMINISTRATOR"
      },
      "condomino": {
        "album": {
          "descricao": "Get access to the project's photo album or general archives.",
          "titulo": "Photo album"
        },
        "leituras": {
          "descricao": "View the history of your unit's consumption meter readings.",
          "titulo": "Readings"
        },
        "relatorios": {
          "descricao": "Access reports such as the Condominium Statement through graphics.",
          "titulo": "Interactive reports"
        },
        "titulo": "MORE CONVENIENCE TO THE CONDOMINIUM"
      },
      "funcionario": {
        "assembleia": {
          "descricao": "Administer all meeting items, such as votes, notices and minutes.",
          "titulo": "- Virtual assembly"
        },
        "boleto": {
          "descricao": "Follow the status of the tickets through situations such as 'Agreement', 'Judgment', 'Notified', among others.",
          "titulo": "- Ticket status"
        },
        "boletos": {
          "descricao": "Eliminate the use of the remittance, registering the slips directly through the system. the download of the return file also through the SGCWEB.",
          "titulo": "- Ticket registration via banking API"
        },
        "conciliacao": {
          "descricao": "Import the bank statement and the system will compare it with your movements.",
          "titulo": "- Bank reconciliation"
        },
        "contasPagar": {
          "descricao": "Manage condominium expenses in an agile and optimized way, controlling notes, taxes, receipts, checks, suppliers, vouchers and others.",
          "titulo": "- Bills to pay"
        },
        "geracao": {
          "descricao": "Set parameters to generate monthly payment slips with just a few clicks.",
          "titulo": "- Automatic generation of monthly billing"
        },
        "inadimplentes": {
          "descricao": "Generate a report of defaulters, with updated corrected value, fine and interest.",
          "titulo": "- Defaulters"
        },
        "lancamentos": {
          "descricao": "Download collection slips and set expenses, and the system will make the accounting movements automatically.",
          "titulo": "- Automatic postings"
        },
        "titulo": "MORE EASIER FOR THE EMPLOYEE"
      },
      "mobile": {
        "ambientes": {
          "descricao": "Consult the availability of the desired environments and periods, and make the lease.",
          "titulo": "Reservation of environments"
        },
        "boletos": {
          "descricao": "Consult your open tickets, without having to ask the administrator.",
          "titulo": "Access to the 2nd copy of tickets"
        },
        "disponivel": "Available to:",
        "notificacoes": {
          "descricao": "Receive notifications when a boleto is due to expire, a reservation is arriving or when an assembly is about to start.",
          "titulo": "Notifications"
        },
        "titulo": "HAVE ACCESS TO THE SYSTEM IN YOUR HANDS"
      }
    },
    "header": {
      "frases": {
        "frase1": {
          "botao": "Test now",
          "descricao": "We are pioneers in the area of software development for accounting offices and condominium administrators in the Itajaí region.",
          "titulo": "More than 25 years of experience in the condominium management market"
        },
        "frase2": {
          "botao": "Test now",
          "descricao": "Made for condominium administrators, SGCWeb aims to streamline management process routines and increase transparency in access to information.",
          "titulo": "Ease and efficiency in managing your condominium"
        },
        "frase3": {
          "botao": "Test now",
          "descricao": "Contact Systemar to create a custom plan for your administrator and receive a 30-day free trial.",
          "titulo": "INTERESTED IN TESTING SGCWEB?"
        }
      }
    },
    "testar": {
      "descricao": "Contact Systemar to create a custom plan for your admin and get a 30-day free trial",
      "liguePara": "CALL TO:",
      "ou": "OR",
      "titulo": "INTERESTED IN TESTING SGCWEB?"
    },
    "topbar": {
      "acessar": "Access",
      "links": {
        "contato": "Contact",
        "funcionalidades": "Functionalities",
        "inicio": "Home",
        "privacidade": "Policy and Privacy"
      }
    }
  }], ["es-ar", {
    "app": {
      "entrarContato": "ENTRAR EN CONTACTO",
      "logoDescricao": "Logotipo SGCWeb",
      "menuAlt": "Menú de iconos",
      "mockupAgrupadoAlt": "Maqueta de dos pantallas de SGCWEB",
      "mockupAlt": "Mockup S9 SGCWeb App",
      "privacidade": {
        "concordar": "Aceptar y proceder",
        "descricao": "Última actualización: 03 de mayo de 2021.",
        "politica": "Política de privacidad",
        "texto1": "Usamos cookies esenciales y tecnologías similares de acuerdo con nuestro",
        "texto2": " y al continuar navegando, usted acepta estas condiciones.",
        "titulo": "POLÍTICA DE PRIVACIDAD DE SGCWEB"
      }
    },
    "contato": {
      "descricao": "¿Tiene alguna pregunta o sugerencia? Póngase en contacto con nosotros mediante el siguiente formulario.",
      "email": {
        "placeholder": "Correo electrónico",
        "titulo": "Correo electrónico:",
        "validacao": "Escriba su correo electrónico"
      },
      "enviar": "Mandar",
      "mensagem": {
        "placeholder": "Su mensaje:",
        "titulo": "Mensaje:",
        "validacao": "Escriba su mensaje primero"
      },
      "nome": {
        "placeholder": "Introduzca su nombre",
        "titulo": "Nombre:",
        "validacao": "Escriba su nombre"
      },
      "telefone": {
        "placeholder": "(DDD) + número de teléfono",
        "titulo": "Teléfono:",
        "validacao": "Ingrese un número de teléfono válido"
      },
      "titulo": "Contacto"
    },
    "footer": {
      "contato": "Contacto",
      "direitos": "Todos los derechos reservados.",
      "endereco": "Habla a",
      "mapa": "Mapa del sitio",
      "sgcwebDescricao": "Creada en base a más de 25 años de experiencia en el mercado de administración de condominios, SGCWeb es una herramienta multiplataforma completamente en la nube con el objetivo de facilitar la vida de quienes administran condominios o viven en ellos.",
      "sigaNos": "Síguenos",
      "sobre": "Sobre nosotros",
      "sobreSystemar": "Acerca de Systemar"
    },
    "funcionalidades": {
      "administradora": {
        "itens": {
          "acesse": {
            "descricao": "Se puede acceder a SGCWEB desde cualquier computadora o celular con internet. ¿Tarea? No hay problema, puedes usar el sistema normalmente.",
            "titulo": "Accede desde cualquier lugar"
          },
          "configuravel": {
            "descricao": "Personalice temas de color, permisos de acceso y horas de trabajo para sus empleados.",
            "titulo": "Configurable"
          },
          "financeiro": {
            "descricao": "Gestiona recibos, boletas de cobro y gastos de condominio de forma fácil y optimizada.",
            "titulo": "Control financiero completo"
          },
          "melhoria": {
            "descricao": "SGCWEB siempre está en constante renovación, que incluye ajustes, nuevas funciones y actualizaciones geniales.",
            "titulo": "Mejora constante"
          },
          "multicondominio": {
            "descricao": "El sistema permite controlar varios condominios simultáneamente, ideal para administradores de condominios.",
            "titulo": "Multi-condominios"
          },
          "relatorios": {
            "descricao": "Decenas de informes de las más variadas categorías, y con varias opciones, para que muestres la información como quieras.",
            "titulo": "Todos los informes que necesitas"
          }
        },
        "titulo": "MÁS EFICIENCIA PARA TU ADMINISTRADOR"
      },
      "condomino": {
        "album": {
          "descricao": "Obtenga acceso al álbum de fotos o archivos generales del proyecto.",
          "titulo": "Álbum de fotos"
        },
        "leituras": {
          "descricao": "Visualiza el historial de lecturas del contador de consumo de tu equipo.",
          "titulo": "Lecturas"
        },
        "relatorios": {
          "descricao": "Accede a informes como la Declaración de Condominio a través de gráficos.",
          "titulo": "Informes interactivos"
        },
        "titulo": "MÁS CONVENIENCIA AL CONDOMINIO"
      },
      "funcionario": {
        "assembleia": {
          "descricao": "Administrar todos los elementos de la reunión, como votos, avisos y actas.",
          "titulo": "- Asamblea virtual"
        },
        "boleto": {
          "descricao": "Siga el estado de los tickets a través de situaciones como 'Acuerdo', 'Sentencia', 'Notificado', entre otras.",
          "titulo": "- Estado del billete"
        },
        "boletos": {
          "descricao": "Elimina el uso de la remesa, registrando los comprobantes directamente a través del sistema. la descarga del archivo de devolución también a través del SGCWEB.",
          "titulo": "- Registro de boletos a través de la API bancaria"
        },
        "conciliacao": {
          "descricao": "Importa el extracto bancario y el sistema lo comparará con tus movimientos.",
          "titulo": "- Conciliación bancaria"
        },
        "contasPagar": {
          "descricao": "Administre los gastos del condominio de forma ágil y optimizada, controlando notas, impuestos, recibos, cheques, proveedores, comprobantes y otros.",
          "titulo": "- Cuentas a pagar"
        },
        "geracao": {
          "descricao": "Establezca parámetros para generar boletas de pago mensuales con solo unos pocos clics.",
          "titulo": "- Generación automática de facturación mensual"
        },
        "inadimplentes": {
          "descricao": "Generar un reporte de morosos, con valor corregido actualizado, multa e intereses.",
          "titulo": "- Morosos"
        },
        "lancamentos": {
          "descricao": "Descarga las boletas de cobro y fija los gastos, y el sistema realizará los movimientos contables de manera automática.",
          "titulo": "- Publicaciones automáticas"
        },
        "titulo": "MÁS FÁCIL PARA EL EMPLEADO"
      },
      "mobile": {
        "ambientes": {
          "descricao": "Consulta la disponibilidad de los ambientes y periodos deseados, y realiza el alquiler.",
          "titulo": "Reserva de ambientes"
        },
        "boletos": {
          "descricao": "Consulta tus tickets abiertos, no hace falta que preguntes al administrador.",
          "titulo": "Acceso a la 2ª copia de billetes"
        },
        "disponivel": "Disponible para:",
        "notificacoes": {
          "descricao": "Reciba notificaciones cuando un boleto esté por vencer, llegue una reserva o cuando una asamblea esté por comenzar.",
          "titulo": "Notificaciones"
        },
        "titulo": "TENGA ACCESO AL SISTEMA EN SUS MANOS"
      }
    },
    "header": {
      "frases": {
        "frase1": {
          "botao": "Prueba ahora",
          "descricao": "Somos pioneros en el área de desarrollo de software para oficinas de contabilidad y administradoras de condominios en la región de Itajaí.",
          "titulo": "Más de 25 años de experiencia en el mercado de administración de condominios"
        },
        "frase2": {
          "botao": "Prueba ahora",
          "descricao": "Pensado para administradores de condominios, SGCWeb tiene como objetivo agilizar las rutinas de los procesos de gestión y aumentar la transparencia en el acceso a la información.",
          "titulo": "Facilidad y eficiencia en la gestión de su condominio"
        },
        "frase3": {
          "botao": "Prueba ahora",
          "descricao": "Comuníquese con Systemar para crear un plan personalizado para su administrador y recibir una prueba gratuita de 30 días.",
          "titulo": "¿INTERESADO EN PROBAR SGCWEB?"
        }
      }
    },
    "testar": {
      "descricao": "Póngase en contacto con Systemar para crear un plan personalizado para su administrador y obtener una prueba gratuita de 30 días",
      "liguePara": "LLAMAR A:",
      "ou": "O",
      "titulo": "¿INTERESADO EN PROBAR SGCWEB?"
    },
    "topbar": {
      "acessar": "Acceso",
      "links": {
        "contato": "Contacto",
        "funcionalidades": "Funcionalidades",
        "inicio": "Comienzo",
        "privacidade": "Política y Privacidad"
      }
    }
  }], ["pt-br", {
    "app": {
      "entrarContato": "ENTRAR EM CONTATO",
      "logoDescricao": "Logo SGCWeb",
      "menuAlt": "Menu ícone",
      "mockupAgrupadoAlt": "Mockup duas telas do SGCWEB",
      "mockupAlt": "Mockup S9 SGCWeb App",
      "privacidade": {
        "concordar": "Concordar e prosseguir",
        "descricao": "Última Atualização: 03 de maio de 2021.",
        "politica": "Política de Privacidade",
        "texto1": "Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa",
        "texto2": " e, ao continuar navegando, você concorda com estas condições.",
        "titulo": "POLÍTICA DE PRIVACIDADE DO SGCWEB"
      }
    },
    "contato": {
      "descricao": "Possui alguma dúvida ou sugestão? Entre em contato conosco através do formulário abaixo.",
      "email": {
        "placeholder": "E-mail",
        "titulo": "E-mail:",
        "validacao": "Digite seu E-mail"
      },
      "enviar": "Enviar",
      "mensagem": {
        "placeholder": "Sua mensagem:",
        "titulo": "Mensagem:",
        "validacao": "Digite sua mensagem antes"
      },
      "nome": {
        "placeholder": "Insira seu nome",
        "titulo": "Nome:",
        "validacao": "Digite seu nome"
      },
      "telefone": {
        "placeholder": "(DDD) + número de telefone",
        "titulo": "Telefone:",
        "validacao": "Preencha um telefone válido"
      },
      "titulo": "Contato"
    },
    "footer": {
      "contato": "Contato",
      "direitos": "Todos os direitos reservados.",
      "endereco": "Endereço",
      "mapa": "Mapa do site",
      "sgcwebDescricao": "Criado com base de mais de 25 anos de experiência no mercado gerenciamento condominial, o SGCWeb é uma ferramenta multiplataforma inteiramente na nuvem com o objetivo de facilitar a vida daqueles que administram condomínios ou vivem neles",
      "sigaNos": "Siga-nos",
      "sobre": "Sobre nós",
      "sobreSystemar": "Sobre a Systemar"
    },
    "funcionalidades": {
      "administradora": {
        "itens": {
          "acesse": {
            "descricao": "O SGCWEB pode ser acessado de qualquer computador ou celular com internet. Trabalhando de casa? Sem problemas, pode usar o sistema normalmente.",
            "titulo": "Acesse em qualquer lugar"
          },
          "configuravel": {
            "descricao": "Personalize os temas de cores e as permissões de acesso e horários de trabalho dos seus funcionários.",
            "titulo": "Configurável"
          },
          "financeiro": {
            "descricao": "Gerencie recebimentos, boleto de cobrança e despesas dos condomínios, de forma facilitada e otimizada.",
            "titulo": "Controle financeiro completo"
          },
          "melhoria": {
            "descricao": "O SGCWEB está sempre em constante renovação, que inclui ajustes, novos recursos e atualizações legais.",
            "titulo": "Constante melhoria"
          },
          "multicondominio": {
            "descricao": "O sistema permite controlar diversos condomínios simultaneamente, ideal para administradores de condomínios.",
            "titulo": "Multicondomínios"
          },
          "relatorios": {
            "descricao": "Dezenas de relatórios das mais variadas categorias, e com diversas opções, para você exibir as informações da forma que quiser.",
            "titulo": "Todos relatórios que você precisar"
          }
        },
        "titulo": "MAIS EFICIÊNCIA PARA A SUA ADMINISTRADORA"
      },
      "condomino": {
        "album": {
          "descricao": "Tenha acesso ao álbum das fotos de obras ou arquivos gerais do empreendimento.",
          "titulo": "Álbum de fotos"
        },
        "leituras": {
          "descricao": "Visualize o histórico das leituras dos medidores de consumo da sua unidade.",
          "titulo": "Leituras"
        },
        "relatorios": {
          "descricao": "Acesse relatórios como o Demonstrativo do condomínio através de gráficos.",
          "titulo": "Relatórios interativos"
        },
        "titulo": "MAIS COMODIDADE AO CONDÔMINO"
      },
      "funcionario": {
        "assembleia": {
          "descricao": "Administre todos os itens da assembleia, como votações, editais e ata.",
          "titulo": "- Assembleia virtual"
        },
        "boleto": {
          "descricao": "Acompanhe os status dos boletos através de situações, como 'Acordo', 'Juízo', 'Notificado', entre outros.",
          "titulo": "- Situação do boleto"
        },
        "boletos": {
          "descricao": "Elimine o uso da remessa, registrando os boletos diretamente através do sistema. o download do arquivo de retorno também através do SGCWEB.",
          "titulo": "- Registro de boletos via API bancária"
        },
        "conciliacao": {
          "descricao": "Importe o extrato bancário e o sistema irá comparar com os seus movimentos.",
          "titulo": "- Conciliação bancária"
        },
        "contasPagar": {
          "descricao": "Gerencie as despesas dos condomínios de forma ágil e otimizada, controlando notas, impostos, recibos, cheques, fornecedores, comprovantes e outros.",
          "titulo": "- Contas a Pagar"
        },
        "geracao": {
          "descricao": "Defina parâmetros para gerar os boletos mensais de cobrança com poucos cliques.",
          "titulo": "- Geração automática de cobrança mensal"
        },
        "inadimplentes": {
          "descricao": "Gere relatório de inadimplentes, com valor corrigido atualizado, multa e juros.",
          "titulo": "- Inadimplentes"
        },
        "lancamentos": {
          "descricao": "Baixe boletos de cobrança e quite despesas, e o sistema fará os movimentos contábeis automaticamente.",
          "titulo": "- Lançamentos automáticos"
        },
        "titulo": "MAIS FACILIDADE PARA O FUNCIONÁRIO"
      },
      "mobile": {
        "ambientes": {
          "descricao": "Consulte a disponibilidade dos ambientes e períodos desejados, e efetue a locação.",
          "titulo": "Reserva de ambientes"
        },
        "boletos": {
          "descricao": "Consulte os seus boletos em aberto, sem necessidade de solicitar à administradora.",
          "titulo": "Acesso à 2ª via de boletos"
        },
        "disponivel": "Disponível para:",
        "notificacoes": {
          "descricao": "Receba notificações quando um boleto for vencer, um reserva estiver chegando ou quando uma assembleia estiver para começar.",
          "titulo": "Notificações"
        },
        "titulo": "TENHA ACESSO AO SISTEMA NAS SUAS MÃOS"
      }
    },
    "header": {
      "frases": {
        "frase1": {
          "botao": "Testar agora",
          "descricao": "Somos pioneiros na área de desenvolvimento de softwares para escritórios de contabilidade e administradoras de condomínios na região de Itajaí.",
          "titulo": "Mais de 25 anos de experiência no mercado de gerenciamento condominial"
        },
        "frase2": {
          "botao": "Testar agora",
          "descricao": "Feito para administradoras de condomínios, o SGCWeb tem como objetivo agilizar as rotinas de processos gerenciais e aumentar a transparência no acesso às informações.",
          "titulo": "Facilidade e eficiência na gestão do seu condomínio"
        },
        "frase3": {
          "botao": "Testar agora",
          "descricao": "Entre em contato com a Systemar para criar um plano personalizado para sua administradora e receba um teste gratuito de 30 dias.",
          "titulo": "INTERESSADO EM TESTAR O SGCWEB?"
        }
      }
    },
    "testar": {
      "descricao": "Entre em contato com a Systemar para criar um plano personalizado para sua administradora e receba um teste gratuito de 30 dias.",
      "liguePara": "LIGUE PARA:",
      "ou": "OU",
      "titulo": "INTERESSADO EM TESTAR O SGCWEB?"
    },
    "topbar": {
      "acessar": "Acessar",
      "links": {
        "contato": "Contato",
        "funcionalidades": "Funcionalidades",
        "inicio": "Início",
        "privacidade": "Política de privacidade"
      }
    }
  }]];
  _exports.default = _default;
});