define("ember-svg-jar/inlined/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st11{fill:#f3f3f3!important}.st12{fill:#e22335!important}</style><circle cx=\"256\" cy=\"256\" r=\"256\" fill=\"#3964c0\"/><path class=\"st11\" d=\"M458.2 413.1L301 255.9 458.1 98.8c-6.5-8.3-13.5-16.3-21.1-23.9-7.6-7.6-15.6-14.6-23.9-21.1L256.1 211 98.9 53.8C90.6 60.3 82.6 67.3 75 75c-7.6 7.6-14.6 15.5-21 23.8L211.2 256 53.9 413.2c6.5 8.3 13.5 16.3 21.1 23.9 7.6 7.6 15.6 14.6 23.9 21.1L256.1 301l157.2 157.2c8.3-6.4 16.2-13.4 23.8-21 7.6-7.8 14.6-15.8 21.1-24.1z\"/><path class=\"st12\" d=\"M59.6 91.8l150.7 150.7 16.2-16.2L75.1 74.9c-5.4 5.4-10.6 11-15.5 16.9zM420.1 59.6L269.9 209.8l16.2 16.2 151-151c-5.4-5.4-11.1-10.5-17-15.4zM301.4 270l-16.2 16.2 151.3 151.3c5.4-5.4 10.6-11 15.5-16.9L301.4 270zM74.7 436.7c5.4 5.4 11.1 10.6 16.9 15.5l150.9-150.9-16.2-16.2L74.7 436.7z\"/><path class=\"st11\" d=\"M322.9 8.8C301.5 3.1 279.1 0 256 0c-23.1 0-45.4 3.1-66.7 8.8v180.4H8.8C3.1 210.5 0 232.9 0 256c0 23.1 3.1 45.5 8.8 66.8h180.5v180.4c21.3 5.7 43.6 8.8 66.7 8.8s45.5-3.1 66.9-8.8V322.8h180.3c5.7-21.3 8.8-43.7 8.8-66.8 0-23.1-3.1-45.5-8.8-66.8H322.9V8.8z\"/><path class=\"st12\" d=\"M290.1 2.3C278.9.8 267.5 0 256 0s-22.9.8-34 2.3v219.8H2.2C.8 233.2 0 244.5 0 256s.8 22.8 2.2 34H222v219.8c11.1 1.5 22.5 2.3 34 2.3s22.9-.8 34.1-2.3V290h219.7c1.5-11.1 2.2-22.4 2.2-34 0-11.5-.8-22.8-2.2-33.9H290.1V2.3z\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Camada_2",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 512 512",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});