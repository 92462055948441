define("ember-svg-jar/inlined/logo-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#004960}</style></defs><g id=\"Layer_2\" data-name=\"Layer 2\"><g id=\"Layer_1-2\" data-name=\"Layer 1\"><path class=\"cls-1\" d=\"M71.85 41.68A5.57 5.57 0 1169.92 34a5.58 5.58 0 011.93 7.68zM36.72 61.47a5.57 5.57 0 11-1.94-7.64 5.59 5.59 0 011.94 7.64zM37 21.1a5.57 5.57 0 11-2-7.64 5.56 5.56 0 012 7.64z\"/><path d=\"M67.55 64.9a8.15 8.15 0 00-.84-.82 35.49 35.49 0 011-51.74 7 7 0 001-1.28 7.3 7.3 0 00-12.58-7.49 7.05 7.05 0 00-.66 1.49 35.38 35.38 0 01-4 9.9 35.42 35.42 0 01-41 15.69l-.18-.05-.82-.25a.09.09 0 010 .05 7.76 7.76 0 00-5.66 14.26 7.65 7.65 0 005.37.93.64.64 0 000 .07 35.3 35.3 0 0145.07 26.71h.08a7.73 7.73 0 003.41 4.37 7.77 7.77 0 009.81-11.84z\" fill=\"#42849a\"/><path d=\"M46.63 41.67l-.11-.1a4.35 4.35 0 01-.71-5.33 4.57 4.57 0 01.83-1l.12-.16a.9.9 0 00-.31-1.23.88.88 0 00-1.22.31.46.46 0 00-.08.19 4.08 4.08 0 01-.5 1.21 4.35 4.35 0 01-5 1.93.29.29 0 00-.1 0 1 1 0 00-1 .44 1 1 0 00.34 1.31 1 1 0 00.65.12 4.38 4.38 0 013.5.42 4.32 4.32 0 012 2.86 1 1 0 00.42.55 1 1 0 001.21-1.46z\" fill=\"#fff\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 72.64 77.8"
    }
  };
  _exports.default = _default;
});